.content {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
  padding: var(--gutter);

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    padding: 3rem 1rem;
  }
}

.px-gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.pl-gutter {
  padding-left: var(--gutter);
}

.pr-gutter {
  padding-right: var(--gutter);
}

.mx-gutter {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.ml-gutter {
  margin-left: var(--gutter);
}

.mr-gutter {
  margin-right: var(--gutter);
}

.right-gutter {
  right: var(--gutter);
}

.left-gutter {
  left: var(--gutter);
}

.stack {
  display: flex;
  flex-direction: column;
}

.remark {
  display: none;
}

.screen {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    mix-blend-mode: multiply;
    z-index: 1;
  }

  &--20 {
    &::after {
      opacity: 0.2;
    }
  }
}

.narrow-container {
  width: 1050px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  background-color: #b4b4b4;
  height: 4px;
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;

  &.full {
    width: 100%;
    max-width: 100%;
  }

  &.white {
    background-color: #fff;
  }
}

.divided-grid-2 {
  grid-template-columns: 1fr 4px 1fr;
}

.divided-grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.divided-grid-2,
.divided-grid-3 {
  display: grid;

  .divider {
    background-color: #b4b4b4;
    height: 400px;
    max-height: 391px;
    width: 4px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    .divider {
      display: none;
    }
  }
}

.hide-text {
  clip-path: inset(0);
}

.flex-full {
  flex: 0 0 100%;
}

.flex-process {
  flex: 0 0 773px;

  @media screen and (max-width: 768px) {
    flex: 0 0 80dvw;
  }
}

.flex-icon {
  flex: 0 0 32px;
}

.max-text {
  width: 760px;
  max-width: 100%;
}

.svg-btn {
  cursor: pointer;
  circle,
  path {
    transition: all 0.3s ease;
  }

  &:hover {
    circle {
      fill: var(--orange);
    }
    path {
      fill: black;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

dialog {
  display: grid;
  place-content: center;
  padding: 1.6rem;
  /* max-width: 400px; */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;

  z-index: 1000000;

  overscroll-behavior: contain;
}

.h-timeline {
  --timeline-width: 15000px;
  height: calc(100vh - 150px);
  position: relative;
  &::after {
    position: fixed;
    content: "";
    width: var(--timeline-width);
    height: 3px;
    background-color: white;
    left: 0;
  }
}
