:root {
  --step--2: clamp(0.4444rem, 0.4251rem + 0.0966vi, 0.5rem);
  --step--1: clamp(0.6667rem, 0.6377rem + 0.1449vi, 0.75rem);
  --step-0: clamp(1rem, 0.9565rem + 0.2174vi, 1.125rem);
  --step-1: clamp(1.5rem, 1.4348rem + 0.3261vi, 1.6875rem);
  --step-2: clamp(2.25rem, 2.1522rem + 0.4891vi, 2.5313rem);
  --step-3: clamp(3.375rem, 3.2283rem + 0.7337vi, 3.7969rem);
  --step-4: clamp(5.0625rem, 4.8424rem + 1.1005vi, 5.6953rem);
  --step-5: clamp(7.5938rem, 7.2636rem + 1.6508vi, 8.543rem);

  --hero-font-size: clamp(5rem, 4.4565rem + 2.7174vw, 6.5625rem);
  --h1-font-size: clamp(3.75rem, 2.7717rem + 4.8913vw, 6.5625rem);
  --h2-font-size: clamp(3.75rem, 2.7717rem + 4.8913vw, 6.5625rem);
  --h3-font-size: clamp(1.5rem, 1.3696rem + 0.6522vi, 1.875rem);
  --h4-font-size: var(--step-3);
  --h5-font-size: var(--step-2);
  --h6-font-size: var(--step-1);
  --p-font-size: 1.25rem;
  --small-font-size: var(--step--1);
  --large-body-font-size: var(--step-1);
  --extra-large-body-font-size: var(--step-2);
  --image-overlay-font-size: clamp(3.125rem, 2.5163rem + 3.0435vi, 4.875rem);

  --hero-font-family: "Redwing", sans-serif;
  --heading-font-family: acumin-pro, sans-serif;
  --body-font-family: acumin-pro, sans-serif;
}
