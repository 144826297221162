h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-weight: 500;
  line-height: 1;
  margin: 0;
  text-wrap: balance;
}

p,
div,
li,
a {
  font-family: var(--body-font-family);
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  font-size: 1.25rem;
}

p {
  color: #616161;
}

blockquote {
  i {
    font-style: normal;
  }
}
