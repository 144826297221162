:root {
  --orange: #cb5f39;
  --yellow: #eee600;
  --dark-blue: #173340;
  --blue: #336778;
  --gray: #e8e8e8;
  --brown: #9f6a28;

  --background: #fff;

  --text-color: black;

  --nav-background: transparent;
}
