@font-face {
  font-family: "United Serif";
  src: url("/fonts/United-Serif-Bold-Semi-Condensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Hairline.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Redwing";
  src: url("/fonts/Redwing-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
