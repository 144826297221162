@tailwind base;
@tailwind components;
@tailwind utilities;

@import "design-tokens";
@import "fonts";
@import "typography";
@import "atoms";
@import "animation";

body {
    accent-color: var(--orange);
}

input,
textarea,
select,
button {
    outline-color: var(--orange);
}

html {
    height: 100%;
}

html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

body {
    font-family: var(--body-font-family);
    font-size: 16px;
    font-weight: 400;
    color: black;
    background-color: var(--background);
    transition: all 1s ease;

    --text-color: black;
}

a {
    color: var(--orange);
}

input::placeholder,
textarea::placeholder {
    color: var(--text-color);
}

@keyframes moveAroundAndGrow {
    0% {
        transform: translateX(-10%) translateY(0) scale(0.25);
    }
    24% {
        transform: translateX(-30%) translateY(0) scale(0.55);
    }
    50% {
        transform: translateX(40%) translateY(5%) scale(0.9);
    }
    75% {
        transform: translateX(-30%) translateY(0) scale(0.65);
    }
    100% {
        transform: translateX(-10%) translateY(0) scale(0.25);
    }
}

@keyframes moveAroundAndGrow1 {
    0% {
        transform: translateX(10%) translateY(0) scale(0.25);
    }
    24% {
        transform: translateX(30%) translateY(0) scale(0.75);
    }
    50% {
        transform: translateX(-40%) translateY(5%) scale(1);
    }
    75% {
        transform: translateX(30%) translateY(0) scale(0.55);
    }
    100% {
        transform: translateX(10%) translateY(0) scale(0.25);
    }
}

.circle {
    animation: moveAroundAndGrow 30s infinite;
    transform-origin: center;
}

.circle-1 {
    animation: moveAroundAndGrow1 30s infinite;
    transform-origin: center;
}

footer {
    a {
        color: var(--cream);
    }
}

.mapboxgl-ctrl-attrib-inner a {
    font-size: 12px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    background-color: transparent !important;
}

.word {
    transition: all 0.25s ease;
}

.main-nav {
    --border-color: #fff;
    transition: all 0.25s ease;
    .nav-layout {
        transition: all 0.25s ease;
        grid-template-columns: max-content auto;
    }

    .logo {
        width: 300px;
        transition: all 0.25s ease;
    }

    .hamburger {
        display: none;
    }

    &.retract {
        transform: translateY(-100%);
        .logo {
            width: 200px;
        }

        .nav-layout {
            padding-top: 1rem;
        }
    }

    @media screen and (max-width: 1180px) {
        .logo {
            width: 200px;
        }
    }

    @media screen and (max-width: 1050px) {
        .logo {
            width: 200px;
        }

        .links {
            display: none;
        }

        .hamburger {
            display: flex;
        }
    }
}

.text-block {
    ul {
        list-style: disc;
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    ol {
        list-style: decimal;
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    b,
    strong {
        font-weight: 600;
    }
}

.round-in {
    transition: all 0.25s ease;
    // clip-path: inset(0% 0% 0% 0% round var(--border-radius));
}

.round-out {
    transition: all 0.25s ease;
    // clip-path: inset(0% 0% 0% 0% round 0);
}

.vimeo-player {
    margin: auto;
    max-width: 1024px;
    width: 100%;
    aspect-ratio: 16 / 9;
}

.footer-ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li,
    a,
    h2 {
        line-height: 1;
    }
}

footer {
    h2 {
        min-height: 40px;
    }
}

.button {
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease;
    transition-delay: 0.1s;
    will-change: auto;
    height: 55px;
    min-height: 55px;
    display: flex;
    .icon {
        transition: all 0.25s ease;
        transition-delay: 0.1s;
        display: inline-flex;
        position: relative;
        will-change: auto;
    }

    &:hover {
        color: var(--dark-blue);

        .icon {
            transform: translateX(10px);
            path {
                fill: var(--cream);
            }
        }
    }

    // &::after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   will-change: auto;
    //   top: 0;
    //   left: 0;
    //   background-color: var(--orange);
    //   z-index: 0;
    //   transition: all 0.25s ease;
    //   transition-delay: 0.1s;
    //   transform: scaleX(0);
    //   transform-origin: left;
    //   border-radius: var(--border-radius);
    // }

    // &:hover::after {
    //   transform: scaleX(1);
    // }
}

@media screen and (max-width: 1380px) {
    .hero-mark {
        margin-top: 100px;
    }
}

.cluster-marker {
    // color: var(--dark-blue);
    // background: var(--orange);
    // border-radius: 50%;
    // padding: 20px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;

    span {
        transition: all 0.25s ease;
    }

    &:hover {
        span {
            opacity: 1;
        }
    }
}

.pip-video {
    opacity: 1;
    transition: all 0.25s ease;

    &.show {
        opacity: 1;
    }

    &.hide {
        opacity: 0;
    }
}

body:has(.dropdown-nav) {
    .pip-video {
        opacity: 0;
    }
}

.mapboxgl-marker:has(svg:hover) {
    z-index: 1000000;
    button {
        z-index: 32;

        svg {
            path {
                fill: var(--light-teal);
            }
        }
    }

    // button {
    //   background: var(--teal);
    // }
}

.mapboxgl-canvas-container:has(.mapboxgl-marker:hover) {
    .mapboxgl-marker:not(:hover) {
        opacity: 0.5;
    }
}

.quote-title {
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

footer {
    background-image: url("/grunge.png");
    background-blend-mode: multiply;
    background-size: cover;
}

.flex-half {
    flex: 1 1 50%;
}

.flex-500 {
    flex: 1 1 auto;
}

.pt-center {
    padding-top: 30vh;
}

.people-grid {
    --n: 3; /* number of rows*/
    --m: 4; /* number of columns */
    --g: 0; /* control the gap */
    --f: 1.2; /* control the scale factor */

    gap: var(--g);
    grid-template-columns: repeat(var(--m), auto);

    img {
        transform: all 0.25s ease;
    }

    .overlay {
        opacity: 0;
        transition: all 0.25s ease;
    }

    .overlay.open {
        opacity: 1;
    }

    .grid-hover {
        transition: all 0.25s ease;
        @media screen and (min-width: 769px) {
            &:hover {
                grid-column: span 2;
                grid-row: span 2;
                transition-delay: 0.2s;

                .overlay {
                    opacity: 1;
                }

                &:nth-child(5n + 5) {
                    grid-column: 4 / 6;
                    grid-row: 1 / 3;
                }

                &:nth-child(15n + 10) {
                    grid-column: 4 / 6;
                    grid-row: 2 / 4;
                }

                &:nth-child(15n + 15) {
                    grid-column: 4 / 6;
                    grid-row: 3 / 5;
                }

                // @media screen and (max-width: 768px) {
                //   &:nth-child(4n + 4) {
                //     grid-column: 4 / 6;
                //     grid-row: 1 / 3;
                //   }

                //   &:nth-child(8n + 8) {
                //     grid-column: 4 / 6;
                //     grid-row: 2 / 4;
                //   }

                //   &:nth-child(12n + 12) {
                //     grid-column: 4 / 6;
                //     grid-row: 3 / 5;
                //   }
                // }
            }
        }
    }
}

.trigger[data-state="open"] {
    svg {
        transform: rotate(45deg);
    }
}

.jobs {
    @media screen and (max-width: 768px) {
        padding-left: var(--gutter);
        padding-right: var(--gutter);
    }
    h2 {
        font-size: var(--h2-font-size);
        text-align: center;
        font-family: var(--hero-font-family);
        text-transform: uppercase;
        font-weight: 500;
    }

    .BambooHR-ATS-board {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .BambooHR-ATS-Department-List {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .BambooHR-ATS-Department-Item {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
        }

        .BambooHR-ATS-Department-Header {
            font-size: var(--h3-font-size);
            font-weight: 500;
            font-family: var(--hero-font-family);
            text-transform: uppercase;
        }

        .BambooHR-ATS-Jobs-List {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .BambooHR-ATS-Jobs-Item {
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 0;
            }
        }

        .BambooHR-ATS-Location {
            color: #ababab;
            font-size: 1rem;
        }
    }
}

.bg-brown {
    --text-color: white;
}

.carousel-btn {
    flex: 0 0 10px;
}

.text-white p {
    color: white;
}

.detail-content {
    ul {
        list-style: disc;
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
    }
}

.quote-text {
    p {
        font-size: 2.25rem;
        color: black;
        font-family:
            United Serif,
            Times,
            san-serif;
        line-height: 1;
        @media screen and (min-width: 768px) {
            font-size: 4.125rem;
        }
    }
}

.rich-text {
    h2 {
        @apply text-h3 font-hero uppercase font-medium;
        margin-bottom: 0;
        line-height: 1;
    }
}

.text-base p {
    font-size: 1.15rem;
}
